import { userAxios } from '@/services'

const itemPresentationList = async (perPage) => {
    try {
        return await userAxios.get(`item_presentation/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemPresentationCreate = async (data) => {
    try {
        return await userAxios.post('item_presentation', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemPresentationUpdate = async (id, data) => {
    try {
        return await userAxios.put(`item_presentation/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemPresentationDelete = async (id) => {
    try {
        return await userAxios.delete(`item_presentation/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemPresentationPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`item_presentation/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemPresentationFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`item_presentation/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemPresentationFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`item_presentation/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    itemPresentationList,
    itemPresentationCreate,
    itemPresentationUpdate,
    itemPresentationDelete,
    itemPresentationPagination,
    itemPresentationFilter,
    itemPresentationFilterPagination
}