<template>
  <div>
    <ItemFilter
      v-if="$can('filter', 'module_modules.type_of_modules.inventory.item')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Item" iconTable="GitBranchIcon" />
      <div class="m-1">
        <ItemEdit
          v-if="isEditItemSidebarActive"
          :is-edit-item-sidebar-active.sync="isEditItemSidebarActive"
          :itemEdit="itemEdit"
          @editItemSuccess="editItemSuccess"
        />
        <ItemAdd
          :is-add-new-item-sidebar-active.sync="isAddNewItemSidebarActive"
          @createItem="createItem"
        />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                v-if="$can('store', 'module_modules.type_of_modules.inventory.item')"
                variant="primary"
                @click="isAddNewItemSidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Item</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="itemView === 'table-view'">
        <LoadingTable v-if="items === null" :columns="fields.length" />
        <b-table
          v-else
          :items="items"
          :fields="fields"
          hover
          bordered
          responsive
          primary-key="id"
          class="position-relative"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(name)="data">
            <div
              class="text-nowrap"
              v-b-tooltip.hover.top="data.item.description"
            >
              <b-avatar
                v-if="data.item.image"
                :src="data.item.image"
                size="40"
                variant="light-primary"
              />
              <b-avatar
                v-else
                :text="data.item.name.charAt(0)"
                size="40"
                variant="light-primary"
              />
              <span>
                {{ data.item.name }}
              </span>
            </div>
          </template>
          <template #cell(dose_price)="data">
            <div class="text-nowrap">
              <span> $ {{ data.item.dose_price }} </span>
            </div>
          </template>
          <template #cell(dose_sale_price)="data">
            <div class="text-nowrap">
              <span> $ {{ data.item.dose_sale_price }} </span>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="text-nowrap">
              <b-badge
                :variant="resolveUserStatusVariant(data.item.status)"
                class="badge-glow cursor-pointer"
                @click="changeStatus(data.item)"
              >
                <span class="align-text-top text-capitalize">{{
                  data.item.status ? $t("Active") : $t("Inactive")
                }}</span>
              </b-badge>
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-primary"
              class="btn-icon"
              v-b-tooltip.hover.top="'Edit'"
              v-if="$can('update', 'module_modules.type_of_modules.inventory.item')"
              @click="editItem(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              class="btn-icon"
              v-b-tooltip.hover.top="'Delete'"
              v-if="$can('destroy', 'module_modules.type_of_modules.inventory.item')"
              @click="deleteItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </div>
      <div v-else>
        <!-- mostrar en tarjeta -->
        <section :class="itemView">
          <b-card
            v-for="product in items"
            :key="product.id"
            class="ecommerce-card border-bottom"
            no-body
          >
            <!-- {{ product }} -->
            <div class="text-center">
              <b-link
                :to="{
                  name: 'admin-inventory-product-details',
                  params: { slug: product.id },
                }"
              >
                <b-img
                  :alt="`${product.name}-${product.id}`"
                  class="card-img-top"
                  width="100%"
                  height="250px"
                  :src="product.image"
                />
              </b-link>
            </div>
            <b-card-body>
              <div class="d-flex justify-content-end">
                <b-badge variant="primary">
                  {{ product.item_status.name }}
                </b-badge>
              </div>
              <h6 class="">
                <b-link
                  class="text-body"
                  :to="{
                    name: 'admin-inventory-product-details',
                    params: { slug: product.id },
                  }"
                >
                  {{ product.name }}
                </b-link>
              </h6>
              <b-card-text>
                {{ product.description }}
              </b-card-text>
              <div class="">
                <div class="">
                  <span> Dose price </span>
                  <h6 class="font-weight-bolder">${{ product.dose_price }} / {{ product.presentations.name}}</h6>
                </div>
                <div class="">
                  <span> Dose price Sale </span>
                  <h6 class="font-weight-bolder">
                    ${{ product.dose_sale_price }} / {{ product.presentations.name}}
                  </h6>
                </div>
              </div>
            </b-card-body>
            <div class="item-options text-center">
              <b-button
                v-if="$can('update', 'module_modules.type_of_modules.inventory.item')"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="btn-icon"
                block
                v-b-tooltip.hover.top="'Edit'"
                @click="editItem(product)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="$can('destroy', 'module_modules.type_of_modules.inventory.item')"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="danger"
                class="btn-icon mt-1"
                v-b-tooltip.hover.top="'Delete'"
                @click="deleteItem(product)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </b-card>
        </section>
      </div>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardBody,
  BLink,
  BImg,
  BRow,
  BCol,
  BTable,
  BSkeletonTable,
  BBadge,
  BAvatar,
  BButton,
  BFormRadioGroup,
  BFormRadio,
  VBTooltip,
} from "bootstrap-vue";
import axiosItem from "@/services/admin/inventory/item";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import LoadingTable from "@/components/LoadingTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import ItemEdit from "./ItemEdit.vue";
import ItemAdd from "./ItemAdd.vue";
import ItemFilter from "./ItemFilter.vue";

import { ref } from "@vue/composition-api";
export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BLink,
    BImg,
    BRow,
    BCol,
    BTable,
    BSkeletonTable,
    BBadge,
    BAvatar,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    ItemEdit,
    ItemAdd,
    ItemFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    LoadingTable,
    GoBack,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        "id",
        { key: "name", label: "Name", sortable: true },
        { key: "presentations.name", label: "Presentation", sortable: true },
        { key: "dose", label: "Dose / Unit", sortable: true },
        { key: "dose_price", label: "Dose Price", sortable: true },
        { key: "dose_sale_price", label: "Dose Sale Price", sortable: true },
        { key: "item_category.name", label: "Category", sortable: true },
        { key: "item_status.name", label: "Item Status", sortable: true },
        { key: "status", label: "status", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      items: null,
      loading: false,
      isEditItemSidebarActive: false,
      isAddNewItemSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      ItemFilter: null,
      itemView: "table-view",
      itemViewOptions: [
        { icon: "AlignJustifyIcon", value: "table-view" },
        { icon: "ListIcon", value: "list-view" },
      ],
    };
  },
  mounted() {
    this.getItem();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getItem();
      } else {
        this.searchFilter(this.ItemFilter);
      }
    },
  },
  methods: {
    getItem() {
      axiosItem
        .itemList(this.perPage)
        .then(({ data, current_page, total, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    editItem(item) {
      this.isEditItemSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editItemSuccess(value) {
      if (value) {
        this.$refs.toast.success("Type Item updated successfully");
      } else {
        this.$refs.toast.error("Error updating type label");
      }
      this.isEditItemSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    createItem({ status }) {
      if (status) {
        this.$refs.toast.success("Item type added successfully");
      } else {
        this.$refs.toast.danger("Item type not added");
      }
      this.isAddNewItemSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosItem.itemDelete(item.id).then((response) => {
            this.$refs.toast.success("Item type has been deleted successfully");
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosItem
          .itemPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosItem
          .itemFilterPagination(this.perPage, page, this.ItemFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.ItemFilter = value;
        axiosItem
          .itemFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getItem();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    changeStatus(item) {
      axiosItem
        .itemUpdate(item.id, { status: !item.status })
        .then((res) => {
          this.$refs.toast.success("User status has been changed successfully");
        })
        .catch((err) => {
          this.$refs.toast.error("User status has not been changed");
        });

      this.pageChanged(this.currentPage.page);
    },
    resolveUserStatusVariant(status) {
      if (status) return "success";
      return "danger";
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
