import { userAxios } from '@/services'

const itemStatusList = async (perPage) => {
    try {
        return await userAxios.get(`item_status/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemStatusCreate = async (data) => {
    try {
        return await userAxios.post('item_status', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemStatusUpdate = async (id, data) => {
    try {
        return await userAxios.put(`item_status/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemStatusDelete = async (id) => {
    try {
        return await userAxios.delete(`item_status/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemStatusPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`item_status/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemStatusFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`item_status/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemStatusFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`item_status/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    itemStatusList,
    itemStatusCreate,
    itemStatusUpdate,
    itemStatusDelete,
    itemStatusPagination,
    itemStatusFilter,
    itemStatusFilterPagination
}