<template>
  <b-sidebar id="add-new-item-sidebar" :visible="isEditItemSidebarActive" bg-variant="white" sidebar-class="sidebar-lg"
    shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-item-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Edit Item</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input id="name" v-model="stateData.name" autofocus :state="getValidationState(validationContext)"
                trim placeholder="Item status name" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category -->
          <validation-provider #default="validationContext" name="Category" rules="required">
            <b-form-group label="Category" label-for="category">
              <v-select id="category" v-model="stateData.item_category_id"
                :state="getValidationState(validationContext)" :options="categoryOptions" :reduce="(value) => value.id"
                label="name" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Presentation -->
          <validation-provider #default="validationContext" name="Presentation" rules="required">
            <b-form-group label="Presentation" label-for="presentation">
              <v-select id="presentation" v-model="stateData.presentations_id"
                :state="getValidationState(validationContext)" :options="presentationOptions"
                :reduce="(value) => value.id" label="name" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dose -->
          <validation-provider #default="validationContext" name="Dose" rules="required">
            <b-form-group label="Dose / Unit" label-for="dose">
              <b-form-input id="dose" v-model="stateData.dose" :state="getValidationState(validationContext)" trim
                placeholder="Dose" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dose Price -->
          <validation-provider #default="validationContext" name="Dose Price" rules="required|min_value:0|decimal">
            <b-form-group label="Dose Price" label-for="dose_price">
              <b-form-input id="dose_price" v-model="stateData.dose_price"
                :state="getValidationState(validationContext)" trim placeholder="Dose" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dose Sale Price -->
          <validation-provider #default="validationContext" name="Dose Sale Price" rules="required|min_value:0|numeric">
            <b-form-group label="Dose Sale Price" label-for="dose_sale_price">
              <b-form-input id="dose_sale_price" v-model="stateData.dose_sale_price"
                :state="getValidationState(validationContext)" trim placeholder="Dose Sale Price" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- item Status -->
          <validation-provider #default="validationContext" name="Item Status" rules="required">
            <b-form-group label="Item Status" label-for="item-status">
              <v-select id="item-status" v-model="stateData.item_statuses_id"
                :state="getValidationState(validationContext)" :options="itemStatusOptions"
                :reduce="(value) => value.id" label="name" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category -->
          <validation-provider #default="validationContext" name="Description" rules="required">
            <b-form-group label="Description" label-for="description">
              <b-form-textarea id="description" v-model="stateData.description"
                :state="getValidationState(validationContext)" placeholder="Description" no-resize rows="3" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Image -->
          <b-form-group label="Image" label-for="image">
            <b-form-file id="image" accept="image/*" v-model="stateData.image" placeholder="image" />
          </b-form-group>

          <!-- status -->
          <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select id="status" v-model="stateData.status" :state="getValidationState(validationContext)"
                :options="optionsStatus" :reduce="(val) => val.value" :clearable="false" label="text" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
              :disabled="loading">
              <span v-if="!loading">Save</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-danger" @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormFile,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";

import axiosItem from "@/services/admin/inventory/item";
import axiosItemCategory from "@/services/admin/inventory/itemCategory";
import axiosEC from "@/services/external-settings/images";
import axiosItemStatus from "@/services/admin/inventory/itemStatus";
import axiosItemPresentation from "@/services/admin/inventory/itemPresentation";

export default {
  components: {
    BSidebar,
    BForm,
  BFormFile,
  BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditItemSidebarActive",
    event: "update:is-edit-item-sidebar-active",
  },
  props: {
    isEditItemSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const stateData = ref(JSON.parse(JSON.stringify(props.itemEdit.value)));
    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value));
    };

    const categoryOptions = ref([]);
    const itemStatusOptions = ref([]);
    const presentationOptions = ref([]);
    onMounted(() => {
      axiosItemCategory.itemCategoryList(10).then(({ data }) => {
        categoryOptions.value = data;
      });
      axiosItemStatus.itemStatusList(10).then(({ data }) => {
        itemStatusOptions.value = data;
      });
      axiosItemPresentation.itemPresentationList(10).then(({ data }) => {
        presentationOptions.value = data;
      });
    });

    const loading = ref(false);
    const uploadPhoto = async (file) => {
      const formData = new FormData();
      formData.append("type", "INVENTORY");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      return dir_img;
    };
    const onSubmit = async () => {
      loading.value = true;
      const photo = await uploadPhoto(stateData.value.image)
      stateData.value.image = photo
      axiosItem
        .itemUpdate(stateData.value.id, stateData.value)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("editItemSuccess", true);
        })
        .catch(() => {
          emit("editItemSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      stateData,
      loading,
      optionsStatus,
      categoryOptions,
      itemStatusOptions,
      presentationOptions,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style></style>
